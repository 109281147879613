import React from "react";
import './ButtonCourse.css';

// Libraries
import { Link } from "react-router-dom";

// Material
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

const ButtonCourse = ({to, type, text}) => {
    return (
        <Link to={to} className={type+" buttonCourse"}><SchoolOutlinedIcon/>{text}</Link>
    );
}

export default ButtonCourse;