import React from "react";
import './Products.css';

// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

// DataBase
import { ProductsList } from '../../databaseProducts';

// Images
import plus from '../../assets/images/plus.svg';

// Libraries
import { Link } from "react-router-dom";

const Products = () => {

    return (
        <section className="products-screen">
            <Header />
            <section className="products-list">
                <div className="products-list-wrapper">
                    {ProductsList.map((item, key) => (
                        <Link className={item.customClass ? item.customClass : "product-wrapper"} key={key} to={"/products/"+item.lineName}>
                            <div className="product-image">
                                <img alt={item.lineName} src={item.lineImage} width="315px" />
                            </div>
                            <div className="product-icon">
                                <img alt="plus-icon" src={plus} />
                            </div>
                            <p className="product-name">{item.lineName}</p>
                        </Link>
                    ))}
                </div>
            </section>
            <Footer />
        </section>
    );
}

export default Products;