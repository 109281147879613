import React from "react";
import './Footer.css';

// Images
import logo from '../../assets/images/logo.png';
import whatsapp from '../../assets/images/whatsapp-brands.svg';
import instagram from '../../assets/images/instagram-brands.svg';
import facebook from '../../assets/images/facebook-brands.svg';
// import linkedin from '../../assets/images/linkedin-brands.svg';
import youtube from '../../assets/images/youtube-brands.svg';

// Components
import ButtonDistributor from '../../components/ButtonDistributor/ButtonDistributor';

// Libraries
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <section className="footer">
            <div className="footer-1">
                <div>
                    <img className='footer-logo' alt="footer-logo" src={logo} />
                    <div className="footer-social-wrapper">
                        <a className="footer-social" href="https://www.instagram.com/vitallisprofissionaloficial/" target="_blank" rel="noreferrer">
                            <img alt="instagram-icon" src={instagram} />
                        </a>
                        {/* <a className="footer-social" href="">
                            <img src={linkedin} />
                        </a> */}
                        <a className="footer-social" href="https://www.youtube.com/channel/UCTJjjQu8_kHhj1V0OJgI5WQ" target="_blank" rel="noreferrer">
                            <img alt="youtube-icon" src={youtube} />
                        </a>
                        <a className="footer-social" href="https://www.facebook.com/vitallisprofissional" target="_blank" rel="noreferrer">
                            <img alt="facebook-icon" src={facebook} />
                        </a>
                    </div>
                </div>
                <div className="footer-links-wrapper">
                    <div>
                        <a className='footer-link link' href="/products">Nossos Produtos</a>
                        {/* <a href="/courses" className="footer-link link">Cursos e Eventos</a> */}
                        <div className="footer-link link">Cursos e Eventos</div>
                    </div>
                    <div>
                        {/* <a className="footer-link link">FAQ</a> */}
                        <a href="/support" className="footer-link link">Suporte</a>
                        <Link to="/#aboutVitallis" className="footer-link link">Sobre Nós</Link>
                    </div>
                    <div>
                        <div className="footer-link bold">Suporte de Atendimento ao Cabelereiro e ao Consumidor:</div>
                        <div className="footer-link" style={{ flexDirection: 'row' }}>
                            <img alt="whatsapp-icon" src={whatsapp} width="18px" style={{ marginRight: '6px' }}/>
                            (73) 8881-0987 / (73) 3291-5977
                        </div>
                        <div className="footer-link email">comercial@vitallisprofissional.com.br</div>
                    </div>
                </div>
                <div className="distributor-wrapper">
                    <ButtonDistributor bgColor={'dark'} footer={true}/>
                </div>

            </div>
            <div className="footer-2">
                <p className="footer-copyright">Todos os direitos reservados a Vitallis Profissional</p>
            </div>
        </section>
    );
}

export default Footer;