import React, { useState } from "react";
import emailjs from 'emailjs-com';

import './BeDistributor.css';
import './BeDistributor-responsive.css';

// Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Alert from '@mui/material/Alert';

const BeDistributor = () => {

    const [open, setOpen] = useState(false);
    const [distributorPhone, setDistributorPhone] = useState('');
    const [uf, setUf] = useState('');

    const configurePhone = (event) => {
        let number=event.target.value.replace(/\D/g,"");  //Remove tudo o que não é dígito
        number=number.replace(/^(\d\d)(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        number=number.replace(/(\d{5})(\d)/,"$1-$2");    //Coloca hífen entre o quinte e o sexto dígito
        setDistributorPhone(number);
    }

    const configureUf = (event) => {
        let text = event.target.value.replace(/[0-9]/g, ''); // Remove tudo que não é string
        const result = text.toUpperCase();                   // Coloca todos os strings em caixa alta
        setUf(result);
    }

    function handleFormSubmit(event){
        event.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID, 
            process.env.REACT_APP_DISTRIBUTOR_TEMPLATE_ID, 
            event.target,
            process.env.REACT_APP_USER_ID
           )
           .then((result) => {
            //    console.log(result.text);
            }, (error) => {
        //    console.log(error.text);
        });

        event.target.reset();
        setUf('');
        setDistributorPhone('');
        setOpen(true);
    }

    return (
        <section className="distributor-screen">
            <Header />   
            <div className="distributor-form-content">
                <div className="distributor-form-wrapper">
                    <div className="distributor-form-label">
                        <h1 className="distributor-form-title no-margin">SOMOS UMA MARCA CRIATIVA E INOVADORA</h1>
                        <h3 className="distributor-form-subtitle no-margin">Seja um de nossos distribuidores</h3>
                    </div>
                    <div className="distributor-form-form">
                        <form style={{position: 'relative'}} onSubmit={handleFormSubmit}>
                            <input className="distributor-form-input no-margin" type="text" name="name" placeholder="Nome Completo" autoComplete="off" required/>
                            <input className="distributor-form-input email" type="email" name="email" placeholder="Email" autoComplete="off" required/>
                            <input className="distributor-form-input" type="text" name="phone" placeholder="Celular" maxLength={15} onChange={configurePhone} value={distributorPhone} autoComplete="off" required/>
                            <input className="distributor-form-input" type="text" name="instagram" placeholder="Instagram" autoComplete="off" required/>
                            <div className="flex" style={{gap: '12px'}}>
                                <input className="distributor-form-input" style={{width: '70%'}} type="text" name="city" placeholder="Cidade" autoComplete="off" required/>
                                <input className="distributor-form-input" style={{width: '30%'}} type="text" name="uf" maxLength={2} onChange={configureUf} value={uf} placeholder="Estado" autoComplete="off" required/>
                            </div>
                            <input className="distributor-form-input title" type="text" name="title" placeholder="Assunto" autoComplete="off" required/>
                            <textarea className="distributor-form-input message" rows="6" cols="1" name="message" placeholder="Mensagem" autoComplete="off" required/>
                            <button className="distributor-form-submit" type="submit">Enviar</button>
                            <Alert variant="filled" severity="success" className="alertSuccess" style={{display: open ? 'flex' : 'none'}} onClose={() => {setOpen(false)}}>Seus dados foram enviados com sucesso!</Alert>
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex h-center relative">
                <div className="fat-line"></div>
                <div className="fat-line-power-wrapper">
                    <div className="fat-line-power">
                        <div className="fat-line-power-button"></div>
                    </div>
                </div>
            </div>
            <div className="distributor-benefits-wrapper">
                <div className="flex flex-column h-center">
                    {/* <h1 className="distributor-benefits-title">Vantagens</h1> */}
                    <div className="distributor-benefits-text-wrapper">
                        <div>
                            <p className="distributor-benefits-text text-end">Vendas exclusivas para salões de beleza, sempre através de Distribuidores Vitallis</p>
                            <span className="distributor-benefits-dot"></span>
                            <p className="distributor-benefits-text">Cursos e treinamentos on-line e presenciais</p>
                        </div>
                        <div>
                            <p className="distributor-benefits-text text-end">Suporte técnico e comercial</p>
                            <span className="distributor-benefits-dot"></span>
                            <p className="distributor-benefits-text">Embalagens exclusivas</p>
                        </div>
                        <div>
                            <p className="distributor-benefits-text text-end">Fabricação própria</p>
                            <span className="distributor-benefits-dot"></span>
                            <p className="distributor-benefits-text">Produtos 100% profissional</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="distributor-benefits-image-wrapper"></div>
            <Footer />
        </section>
    );
}

export default BeDistributor;