import React from "react";
import './ProductDetails.css';

// Libraries
import { useParams  } from 'react-router-dom';

// Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SliderProducts from "../../components/SliderProducts/SliderProducts";

// DataBase
import { ProductsList } from '../../databaseProducts';

const ProductDetails = () => {

    const productInfo = [];
    let { product } = useParams();

    ProductsList.forEach((item) => {
        if(item.lineName === product){
           productInfo.push(item); 
        } 
    } ); // SQL: SELECT * FROM productsTable WHERE lineName == product

    return (
        <section className="products-details-screen">
            <Header />
            <div className="flex h-center flex-column">
                {productInfo[0].lineProducts.map((item, key) => {
                    return item.description ?
                        (<div className="products-details-content" key={key}>
                            <div className="products-details-wrapper">
                                <div> 
                                    <h1 className="products-details-title">{ item.description.lineName }</h1>
                                    <div className="products-details-info">
                                        <div className="horizontal-line"></div>
                                        <p className="products-details-description">
                                            { item.description.text }
                                        </p>
                                    </div>
                                    <img className="products-details-sample" style={{ display: item.description.sampleImage ? 'flex' : 'none' }} alt={`vitallis-${item.description.text}-amostra`} src={ item.description.sampleImage } />
                                </div>
                                <div className="products-details-image">
                                    <img alt={`vitallis-${item.description.text}`} src={ item.description.mainImage } />
                                </div>
                            </div>
                            
                            <SliderProducts products={item.products}/>
                        </div>) : (
                            <div className="new-line" key={key}>
                                <SliderProducts products={item.products} />
                            </div>
                        );
                })}
            </div>
            <Footer />
        </section>
    );
}

export default ProductDetails;