import React, { useState } from "react";
import emailjs from 'emailjs-com';

import './Support.css';
import './Support-responsive.css';

// Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Alert from '@mui/material/Alert';

const Support = () => {

    const [open, setOpen] = useState(false);
    const [supportPhone, setSupportPhone] = useState('');
    const [uf, setUf] = useState('');

    const configurePhone = (event) => {
        let number=event.target.value.replace(/\D/g,"");  //Remove tudo o que não é dígito
        number=number.replace(/^(\d\d)(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        number=number.replace(/(\d{5})(\d)/,"$1-$2");    //Coloca hífen entre o quinte e o sexto dígito
        setSupportPhone(number);
    }

    const configureUf = (event) => {
        let text = event.target.value.replace(/[0-9]/g, ''); // Remove tudo que não é string
        const result = text.toUpperCase();                   // Coloca todos os strings em caixa alta
        setUf(result);
    }

    function handleFormSubmit(event){
        event.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID, 
            process.env.REACT_APP_HAIRDRESSER_TEMPLATE_ID, 
            event.target,
            process.env.REACT_APP_USER_ID
           )
           .then((result) => {
            //    console.log(result.text);
            }, (error) => {
        //    console.log(error.text);
        });

        event.target.reset();
        setUf('');
        setSupportPhone('');
        setOpen(true);
    }

    return (
        <section className="support-screen">
            <Header />   
            <div className="support-form-content">
                <div className="support-form-wrapper">
                    <div className="support-form-title-wrapper">
                        <h3 className="support-form-subtitle">atendimento ao</h3>
                        <h1 className="support-form-title">Cabeleireiro</h1>
                    </div>
                    <div className="support-form-form">
                        <form style={{position: 'relative'}} onSubmit={handleFormSubmit}>
                            <div className="flex support-form-input-wrapper" style={{gap: '12px'}}>
                                <input className="support-form-input" style={{width: '60%'}} type="text" name="name" placeholder="Nome Completo" autoComplete="off" required/>
                                <input className="support-form-input" type="text" style={{width: '40%'}} name="phone" placeholder="Celular / Whatsapp" maxLength={15} onChange={configurePhone} value={supportPhone} autoComplete="off" required/>
                            </div>
                            <input className="support-form-input" type="text" name="instagram" placeholder="Instagram" autoComplete="off" required/>
                            <input className="support-form-input" type="text" name="email" placeholder="Email" autoComplete="off" required/>
                            <div className="flex" style={{gap: '12px'}}>
                                <input className="support-form-input" style={{width: '70%'}} type="text" name="city" placeholder="Cidade" autoComplete="off" required/>
                                <input className="support-form-input" style={{width: '30%'}} type="text" name="uf" maxLength={2} onChange={configureUf} value={uf} placeholder="Estado" autoComplete="off" required/>
                            </div>
                            <input className="support-form-input" type="text" name="title" placeholder="Assunto" autoComplete="off" required/>
                            <textarea className="support-form-input" rows="6" cols="1" name="title" placeholder="Mensagem" autoComplete="off" required/>
                            <button className="support-form-submit" type="submit">Enviar</button>
                            <Alert variant="filled" severity="success" className="alertSuccess" style={{display: open ? 'flex' : 'none'}} onClose={() => {setOpen(false)}}>Seus dados foram enviados com sucesso!</Alert>
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex h-center relative">
                <div className="fat-line"></div>
                <div className="fat-line-power-wrapper">
                    <div className="fat-line-power">
                        <div className="fat-line-power-button"></div>
                    </div>
                </div>
            </div>
            <div className="support-image-wrapper"></div>
            <Footer />
        </section>
    );
}

export default Support;