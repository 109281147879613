import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";

// Components
import Home from './screens/HomeScreen/Home';
import Products from './screens/ProductsScreen/Products';
import ProductDetails from './screens/ProductDetailsScreen/ProductDetails';
import BeDistributor from './screens/BeDistributorScreen/BeDistributor';
import Support from './screens/SupportScreen/Support';
import Courses from './screens/CoursesScreen/Courses';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="products/:product" element={<ProductDetails />} />
        <Route path="be-distributor" element={<BeDistributor />} />
        <Route path="support" element={<Support />} />
        {/* <Route path="courses" element={<Courses />} /> */}
      </Routes>
    </div>
  );
}

export default App;
