import React, { useState } from "react";
import './SliderProducts.css';

// Images
import ArrowLeft from '../../assets/images/slider-arrow-left.png';
import ArrowRight from '../../assets/images/slider-arrow-right.png';
import SliderBackImage from '../../assets/images/product-slider-back.png';
import SliderNextImage from '../../assets/images/product-slider-next.png';

const SliderProducts = ({products}) => {

    const [i, setI] = useState(0);

    const nextSlide = () => {
        setI(i+1);
        if(i >= products.length - 1){
            setI(0);
        }
    }

    const backSlide = () => {
        setI(i-1);
        if(i <= 0){
            setI(products.length - 1);
        }
    }

    return (<>
        <div className="products-slider-wrapper">
            <div className="flex flex-column h-center">
            <div className="background-slider"></div>
                <div className="products-slider">
                    <div className="products-slider-back">
                        <img alt="arrow-slider-back" src={SliderBackImage} />
                    </div>
                    <div className="products-slider-center">
                        <img alt={`vitallis-product-${products[i].title}`} src={products[i].mainImage} />
                    </div>
                    <div className="products-slider-next">
                        <img alt="arrow-slider-next" src={SliderNextImage} />
                    </div>
                </div>
                
                <div className="products-slider-controller-wrapper" style={{display: products.length > 1 ? 'flex' : 'none'}}>
                    <div className="products-slider-controller" onClick={backSlide}>
                        <img alt="arrow-back" src={ArrowLeft} width="28px"/>
                        Anterior
                    </div>
                    <div className="products-slider-controller" onClick={nextSlide}>
                        Próximo
                        <img alt="arrow-next" src={ArrowRight} width="28px"/>
                    </div>
                </div>
            </div>

            <div className="flex flex-column v-center" style={{maxHeight: 525, gap: 0, alignItems: "flex-start"}}>
                <h1 className="products-slider-title no-margin">{ products[i].title }</h1>
                <p className="products-slider-text">{ products[i].text }</p>
            </div>
        </div>
        </>
    );
}

export default SliderProducts;