import React from 'react';
import './Home.css';
import './Home-responsive.css';

// Material
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Components
import ButtonCourse from '../../components/ButtonCourse/ButtonCourse';
import ButtonDistributor from '../../components/ButtonDistributor/ButtonDistributor';
import Footer from '../../components/Footer/Footer';

// Images
import bgSlider1 from '../../assets/images/bg-slider.png';
import bgSlider2 from '../../assets/images/bg-slider2.png';
// import bgSlider3 from '../../assets/images/bg-slider3.png';
import bgSlider4 from '../../assets/images/bg-slider4.png';
// import bgSlider5 from '../../assets/images/bg-slider5.png';
import bgSlider6 from '../../assets/images/bg-slider6.jpg';
import bgSlider7 from '../../assets/images/bg-slider7.jpg';
import logo from '../../assets/images/logo.png';
// import homeProduct1 from '../../assets/images/home-product-1.jpg';
// import homeProduct2 from '../../assets/images/home-product-2.jpg';

// Libraries
import { Link } from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';



const Home = () => {

  const slideProperties = {
    arrows: true,
    prevArrow: <div className="prev"><KeyboardArrowLeftIcon sx={{ fontSize: 30 }}/></div>,
    nextArrow: <div className='next'><KeyboardArrowRightIcon sx={{ fontSize: 30 }}/></div>,
    duration: 9000,
    transitionDuration: 1000
  };

  const slidePropertiesDown = {
    arrows: true,
    prevArrow: <div className="prev"><KeyboardArrowLeftIcon sx={{ fontSize: 30 }}/></div>,
    nextArrow: <div className='next'><KeyboardArrowRightIcon sx={{ fontSize: 30 }}/></div>,
    duration: 4000,
    transitionDuration: 1000
  };

  return (
    <section className="home-screen">
      {/* <header className='home-header fixed'>
        <img className='home-logo' src={logo} />
        <div className="flex">
          <div className='home-link-wrapper'>
            <Link className='home-link' to="/products">Nossos Produtos</Link>
            <div className='home-link-underline'></div>
          </div>
          <div className='home-link-wrapper'>
            <Link className='home-link' to={'/courses'}>Cursos e Eventos</Link>
            <div className='home-link-underline'></div>
          </div>
        </div>
        <div className='flex home-button-wrapper'>
          <ButtonCourse text={"Atendimento ao Cabelereiro"} to={'/support'} type={'empty'}/>
          <ButtonDistributor bgColor={'dark'}/>
        </div>
      </header> */}

      <header className='home-header'>
        <img className='home-logo' alt="vitallis-logo" src={logo} />
        <div className="flex">
          <div className='home-link-wrapper'>
            <Link className='home-link' to="/products">Nossos Produtos</Link>
            <div className='home-link-underline'></div>
          </div>
          {/* <div className='home-link-wrapper'>
            <Link className='home-link' to={'/courses'}>Cursos e Eventos</Link>
            <div className='home-link-underline'></div>
          </div> */}
        </div>
        <div className='flex home-button-wrapper'>
          <ButtonCourse text={"Atendimento ao Cabelereiro"} to={'/support'} type={'empty'}/>
          <ButtonDistributor bgColor={'dark'}/>
        </div>
      </header>


      <section className='home-slider' >
        <div className='home-slider-wrapper'>
          <div className='home-slider-controller'>  
            {/* <div className="prev">
              <KeyboardArrowLeftIcon sx={{ fontSize: 30 }}/>
            </div>
            <div className='next'>
              <KeyboardArrowRightIcon sx={{ fontSize: 30 }}/>
            </div> */}
          </div>
          <Slide {...slideProperties}>
              {/* <a href='https://doity.com.br/lexperience-conference-coiffeur-vitallis' target='_blank' rel="noreferrer"><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider5})` }}></div></a> */}
              <Link to={'/products/Connection'}><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider6})` }}></div></Link>
              <Link to={'/products/Essential Finished'}><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider7})`, backgroundPosition: 'bottom', backgroundSize: '90%' }}></div></Link>
              <Link to={'/products/Recovery'}><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider4})` }}></div></Link>
              <Link to={'/products/Sublime Curls'}><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider1})` }}></div></Link>
              <Link to={'/products/V.I.Plex'}><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider2})` }}></div></Link>
              {/* <Link to={'/courses'}><div className='home-slider-image' style={{ 'backgroundImage': `url(${bgSlider3})` }}></div></Link> */}
          </Slide>
        </div>

      </section>

      <section id='aboutVitallis' className='home-about'>
        <div className='home-about-wrapper'>
          <div className='home-about-info'>
            <h2 className='home-title-big no-margin'>
              Uma Nova Vitallis
            </h2>
            <p className='home-about-text'>
              Uma empresa moderna e extremamente arrojada.<br/>
              A Vitallis Profissional nasceu do desejo de criar o melhor cosmético nacional em termos de qualidade, conceito, benefícios e preço justo. Sua proposta é promover o bem estar e a autoestima de seus clientes, com produtos exclusivos.<br/>
              Com o conceito inovador da marca ganhamos espaço em pouco tempo e encantamos grandes profissionais do mercado.
            </p>
          </div>
          <div className='home-about-image-wrapper'>
            <div className='home-about-image'></div>
          </div>
        </div>
        <div className='line-small'></div>
      </section>

      <section className='home-products'>
        <div className='home-products-wrapper'>
          {/* <img className='home-product-image' src={homeProduct1} />
          <img className='home-product-image' src={homeProduct2} /> */}
          <div className='home-products-example'>
            <div className='home-products-example-image home-product-1'></div>
          </div>
          <div className='home-products-example'>
            <div className='home-products-example-image home-product-2'></div>
          </div>
        </div>
      </section>

      <section className='home-schedule'>
        <div className='home-schedule-content'>
          <h2 className='home-title-normal no-margin'>Saúde dos fios</h2>
          <p className='home-text-big'>#CronogramaVitallisProfissional</p>
          <div className='line-small-yellow'></div>

          <div className='home-schedule-wrapper'>
            <div className='home-products-slider-wrapper'>
              <Slide {...slidePropertiesDown}>
                <div style={{width: 'max-content', margin: 'auto',}} >
                  <div className='home-schedule-products'>
                    <Link className='home-schedule-products-wrapper' to="/products/V.I.Plex">
                      <div className='home-schedule-product-index'>1</div>
                      <div className='home-schedule-product-image index-1'></div>
                      <div className='home-schedule-product-name'>V.i.plex</div>
                    </Link>
                    <Link className='home-schedule-products-wrapper' to="/products/Recovery">
                      <div className='home-schedule-product-index bg-black'>2</div>
                      <div className='home-schedule-product-image index-2'></div>
                      <div className='home-schedule-product-name'>Recovery</div>
                    </Link>
                    <Link className='home-schedule-products-wrapper' to="/products/Plástica%20Premium">
                      <div className='home-schedule-product-index bg-black'>3</div>
                      <div className='home-schedule-product-image index-3'></div>
                      <div className='home-schedule-product-name'>Plástica Premium</div>
                    </Link>
                    {/* <div className='home-schedule-products-wrapper'>
                      <div className='home-schedule-product-index'>3</div>
                      <div className='home-schedule-product-image index-3'>
                        AGUARDE
                      </div>
                      <div className='home-schedule-product-name'>Novidades</div>
                    </div> */}
                  </div>

                  <div className='home-schedule-list-wrapper'>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index'>1</div>
                      <p className='home-schedule-list-text no-margin'>preparação para químicas</p>
                    </div>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index bg-black'>2</div>
                      <p className='home-schedule-list-text no-margin'>até dois dias após a química</p>
                    </div>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index'>3</div>
                      <p className='home-schedule-list-text no-margin'>tratamento de alta performance</p>
                    </div>
                  </div>
                </div>

                <div style={{width: 'max-content', margin: 'auto'}}>
                  <div className='home-schedule-products'>
                    <Link className='home-schedule-products-wrapper' to="/products/Limited%20Edition">
                      <div className='home-schedule-product-index'>1</div>
                      <div className='home-schedule-product-image index-4'></div>
                      <div className='home-schedule-product-name'>Banho de Colágenos</div>
                    </Link>
                    <Link className='home-schedule-products-wrapper' to="/products/Limited%20Edition">
                      <div className='home-schedule-product-index bg-black'>2</div>
                      <div className='home-schedule-product-image index-5'></div>
                      <div className='home-schedule-product-name'>Demaia Cabelos K.O</div>
                    </Link>
                    <Link className='home-schedule-products-wrapper' to="/products/Limited%20Edition">
                      <div className='home-schedule-product-index'>3</div>
                      <div className='home-schedule-product-image index-6'></div>
                      <div className='home-schedule-product-name'>Anabolizante Capilar</div>
                    </Link>
                  </div>

                  <div className='home-schedule-list-wrapper'>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index'>1</div>
                      <p className='home-schedule-list-text no-margin'>Ultra Hidratação</p>
                    </div>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index bg-black'>2</div>
                      <p className='home-schedule-list-text no-margin'>Ultra Nutrição</p>
                    </div>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index'>3</div>
                      <p className='home-schedule-list-text no-margin'>Ultra Fortalecimento</p>
                    </div>
                  </div>
                </div>

                <div style={{width: 'max-content', margin: 'auto'}}>
                  <div className='home-schedule-products'>
                    <Link className='home-schedule-products-wrapper' to="/products/Power%20Relieve">
                      <div className='home-schedule-product-index'>1</div>
                      <div className='home-schedule-product-image index-7'></div>
                      <div className='home-schedule-product-name'>Semi di Lino</div>
                    </Link>
                    <Link className='home-schedule-products-wrapper' to="/products/Power%20Relieve">
                      <div className='home-schedule-product-index bg-black'>2</div>
                      <div className='home-schedule-product-image index-8'></div>
                      <div className='home-schedule-product-name'>Mousse</div>
                    </Link>
                    <Link className='home-schedule-products-wrapper' to="/products/Power%20Relieve">
                      <div className='home-schedule-product-index'>3</div>
                      <div className='home-schedule-product-image index-9'></div>
                      <div className='home-schedule-product-name'>Cistina</div>
                    </Link>
                  </div>

                  <div className='home-schedule-list-wrapper'>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index'>1</div>
                      <p className='home-schedule-list-text no-margin'>Hidratação Instantânea</p>
                    </div>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index bg-black'>2</div>
                      <p className='home-schedule-list-text no-margin'>Reconstrução das pontes de sulfeto</p>
                    </div>
                    <div className='home-schedule-list-item'>
                      <div className='home-schedule-product-index'>3</div>
                      <p className='home-schedule-list-text no-margin'>Nutrição e massa</p>
                    </div>
                  </div>
                </div>
              </Slide>
            </div>
          </div>

        </div>
      </section>

      <Footer/>
    </section>
  );
}

export default Home;
