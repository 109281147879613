import adoroImage from './assets/images/adoro.png';
import limitedImage from './assets/images/limited.png';
import desiredImage from './assets/images/desired.png';
import superImage from './assets/images/super.png';
import lissImage from './assets/images/liss.png';
import viplexImage from './assets/images/viplex.png';
import sublimeImage from './assets/images/sublime.png';
import powerImage from './assets/images/power.png';
import recoveryImage from './assets/images/recovery.png';
import intenseImage from './assets/images/intense.png';
import blondImage from './assets/images/blond.png';
import liveImage from './assets/images/live.png';
import essentialImage from './assets/images/essential.png';
import plasticaImage from './assets/images/plastica.png';
import connectionImage from './assets/images/connection.jpg';


import adoroDescriptionMainImage from './assets/images/adoro-main.png';
import limitedDescriptionMainImage from './assets/images/limited-main.png';
import desiredDescriptionMainImage from './assets/images/desired-main.png';
import superDescriptionMainImage from './assets/images/super-main.png';
import lissDescriptionMainImage from './assets/images/liss-main.png';
import viplexDescriptionMainImage from './assets/images/viplex-main.png';
import sublimeDescriptionMainImage from './assets/images/sublime-main.png';
import powerDescriptionMainImage from './assets/images/power-main.png';
import recoveryDescriptionMainImage from './assets/images/recovery-main.png';
import plasticaMainImage from './assets/images/plastica-main.png';

/* ADORO */
import adoroSliderImage from  './assets/ProdutosSlides/Adoro/gold mask.png';
import adoroSliderImage2 from './assets/ProdutosSlides/Adoro/shampoo gold hydra.png';

/* DESIRED COLOR */
import desiredSliderImage from  './assets/ProdutosSlides/DesiredColor/coloracao creme.png';
import desiredSliderImage2 from './assets/ProdutosSlides/DesiredColor/TonalizanteCreme.png';
import desiredSliderImage3 from './assets/ProdutosSlides/DesiredColor/oxigenada.png';
import desiredSliderImage4 from './assets/ProdutosSlides/DesiredColor/po-descolorante.png';

/* LIMITED EDITION */
import limitedSliderImage from  './assets/ProdutosSlides/LimitedEdition/anabolizante.png';
import limitedSliderImage2 from './assets/ProdutosSlides/LimitedEdition/banho colagenos.png';
import limitedSliderImage3 from './assets/ProdutosSlides/LimitedEdition/Botox capilar.png';
import limitedSliderImage4 from './assets/ProdutosSlides/LimitedEdition/DesmaiaCabelo.png';

/* LISS DAY */
import lissSliderImage from  './assets/ProdutosSlides/LissDay/Balm Sealing.png';
import lissSliderImage2 from './assets/ProdutosSlides/LissDay/leave-in thermo.png';
import lissSliderImage3 from './assets/ProdutosSlides/LissDay/serum reductor.png';
import lissSliderImage4 from './assets/ProdutosSlides/LissDay/shampoo conditioning.png';

/* POWER RELIEVE */
import powerSliderImage from  './assets/ProdutosSlides/PowerRelieve/Cristina.png';
import powerSliderImage2 from './assets/ProdutosSlides/PowerRelieve/mousse.png';
import powerSliderImage3 from './assets/ProdutosSlides/PowerRelieve/semiDiLino.png';

/* RECOVERY */
import recoverySliderImage from  './assets/ProdutosSlides/Recovery/blend fible.png';
import recoverySliderImage2 from './assets/ProdutosSlides/Recovery/deep mask.png';
import recoverySliderImage3 from './assets/ProdutosSlides/Recovery/Instant power.png';
import recoverySliderImage4 from './assets/ProdutosSlides/Recovery/shampoo repair.png';
import recoverySliderImage5 from './assets/ProdutosSlides/Recovery/Shampoo hydrating.png';
import recoverySliderImage6 from './assets/ProdutosSlides/Recovery/replenishing.png';
import recoverySliderImage7 from './assets/ProdutosSlides/Recovery/finisher defrizzer.png';

/* SUBLIME CURLS */
import sublimeSliderImage from  './assets/ProdutosSlides/SublimeCurls/ativador de cachos.png';
import sublimeSliderImage2 from './assets/ProdutosSlides/SublimeCurls/conditioner.png';
import sublimeSliderImage3 from './assets/ProdutosSlides/SublimeCurls/CremeRelaxante.png';
import sublimeSliderImage4 from './assets/ProdutosSlides/SublimeCurls/gel protetor.png';
import sublimeSliderImage5 from './assets/ProdutosSlides/SublimeCurls/liquido ativador.png';
import sublimeSliderImage6 from './assets/ProdutosSlides/SublimeCurls/shampoo indicador.png';
import sublimeSliderImage7 from './assets/ProdutosSlides/SublimeCurls/SoftShampoo Padrão.png';
import sublimeSliderImage8 from './assets/ProdutosSlides/SublimeCurls/ultrahidratante.png';

/* VIP */
import vipSliderImage from  './assets/ProdutosSlides/Vip/VIP MASCARA.png';
import vipSliderImage2 from './assets/ProdutosSlides/Vip/VIP POWER DOSE.png';

/* SUPER */
import superSliderImage from  './assets/ProdutosSlides/SuperSleek/sosCistina.png';

/* INTENSE */
import intenseSliderImage from  './assets/ProdutosSlides/IntenseTherapy/condicionador cabelos secos.png';
import intenseSliderImage2 from './assets/ProdutosSlides/IntenseTherapy/condicionador oleosos.png';
import intenseSliderImage3 from './assets/ProdutosSlides/IntenseTherapy/condicionador quin.png';
import intenseSliderImage4 from './assets/ProdutosSlides/IntenseTherapy/creme para pentiar cabelos secos.png';
import intenseSliderImage5 from './assets/ProdutosSlides/IntenseTherapy/creme para pentiar quin.png';
import intenseSliderImage6 from './assets/ProdutosSlides/IntenseTherapy/hidratante cabelos secos.png';
import intenseSliderImage7 from './assets/ProdutosSlides/IntenseTherapy/hidratante quin.png';
import intenseSliderImage8 from './assets/ProdutosSlides/IntenseTherapy/hidratanteoleosos.png';
import intenseSliderImage9 from  './assets/ProdutosSlides/IntenseTherapy/shampoo cabelos secos.png';
// import intenseSliderImage10 from './assets/ProdutosSlides/IntenseTherapy/shampoo hidratante prof 1.png';
// import intenseSliderImage11 from './assets/ProdutosSlides/IntenseTherapy/shampoo hidratante prof 2.png';
// import intenseSliderImage12 from './assets/ProdutosSlides/IntenseTherapy/shampoo hidratante prof 3.png';
// import intenseSliderImage13 from './assets/ProdutosSlides/IntenseTherapy/shampoo hidratante prof 4.png';
import intenseSliderImage14 from './assets/ProdutosSlides/IntenseTherapy/shampoo oleosos.png';
import intenseSliderImage15 from './assets/ProdutosSlides/IntenseTherapy/shampoo quin.png';

/* BLOND DESIRED */
// import blondSliderImage from  './assets/ProdutosSlides/BlondDesired/Violet Hidratante prof.png';
import blondSliderImage2 from './assets/ProdutosSlides/BlondDesired/Violet Restructing.png';
// import blondSliderImage3 from './assets/ProdutosSlides/BlondDesired/Violet Shampoo prof.png';
import blondSliderImage4 from './assets/ProdutosSlides/BlondDesired/Violet Shampoo.png';

/* LIVE LISS */
import liveSliderImage from  './assets/ProdutosSlides/LiveLiss/gloss Finish.png';
import liveSliderImage2 from './assets/ProdutosSlides/LiveLiss/maskrepair.png';
import liveSliderImage3 from './assets/ProdutosSlides/LiveLiss/ShampooHidratant.png';

/* ESSENTIAL */
import essentialSliderImage from  './assets/ProdutosSlides/Essential/argan oil fluid.png';
import essentialSliderImage2 from './assets/ProdutosSlides/Essential/disciplinador de fios.png';
import essentialSliderImage3 from './assets/ProdutosSlides/Essential/finalizador de brilo.png';
import essentialSliderImage4 from './assets/ProdutosSlides/Essential/reparador de pontas.png';
import essentialSliderImage5 from './assets/ProdutosSlides/Essential/hair-spray-produto.jpg';

/* PLASTIC PREMIUM */ 
import plasticSliderImage from  './assets/ProdutosSlides/PlasticPremium/step-1.png';
import plasticSliderImage2 from './assets/ProdutosSlides/PlasticPremium/step-2.png';
import plasticSliderImage3 from './assets/ProdutosSlides/PlasticPremium/step-3.png';
import plasticSliderImage4 from './assets/ProdutosSlides/PlasticPremium/step-4.png';
import plasticSliderImage5 from './assets/ProdutosSlides/PlasticPremium/shampoo.png';
import plasticSliderImage6 from './assets/ProdutosSlides/PlasticPremium/mascara.png';
import plasticSliderImage7 from './assets/ProdutosSlides/PlasticPremium/oil-repair.png';


/* CONNECTION */
import connectionSliderImage from  './assets/ProdutosSlides/Connection/bb-cream.jpg';
import connectionSliderImage2 from './assets/ProdutosSlides/Connection/cc-bifasico.jpg';
import connectionSliderImage3 from './assets/ProdutosSlides/Connection/dd-cream.jpg';

import adoroSampleImage from './assets/images/adoro-sample.png';
import limitedSampleImage from './assets/images/limited-sample.png';
import desiredSampleImage from './assets/images/desired-sample.png';
import superSampleImage from './assets/images/super-sample.png';
import lissSampleImage from './assets/images/liss-sample.png';
import viplexSampleImage from './assets/images/viplex-sample.png';
import sublimeSampleImage from './assets/images/sublime-sample.png';
import powerSampleImage from './assets/images/power-sample.png';
import recoverySampleImage from './assets/images/recovery-sample.png';
import connectionSampleImage from './assets/images/connection-sample.png';

// import adoroProductMainImage from './assets/images/adoro-product-main.png';
// import limitedProductMainImage from './assets/images/limited-product-main.png';
// import desiredProductMainImage from './assets/images/desired-product-main.png';
// import superProductMainImage from './assets/images/super-product-main.png';
// import lissProductMainImage from './assets/images/liss-product-main.png';
// import liss2ProductMainImage from './assets/images/liss2-product-main.png';
// import viplexProductMainImage from './assets/images/viplex-product-main.png';
// import viplex2ProductMainImage from './assets/images/viplex2-product-main.png';
// import sublimeProductMainImage from './assets/images/sublime-product-main.png';
// import sublime2ProductMainImage from './assets/images/sublime2-product-main.png';
// import powerProductMainImage from './assets/images/power-product-main.png';
// import recoveryProductMainImage from './assets/images/recovery-product-main.png';

import brasilIcon from './assets/images/brasil-icon.png';
import teacher1Image from './assets/images/teacher-1.png';
import teacher2Image from './assets/images/teacher-2.png';
import teacher3Image from './assets/images/teacher-3.png';

export const TeachersList = [
    [
        {
            image: teacher2Image,
            name: "Lucas Delesporte",
            countryName: "",
            countryImage: brasilIcon,
            aboutMe: ""
        },
        {
            image: teacher3Image,
            name: "Laysa Santos",
            countryName: "",
            countryImage: brasilIcon,
            aboutMe: ""
        },
                {
            image: teacher1Image,
            name: "Deivide Medeiros",
            countryName: "",
            countryImage: brasilIcon,
            aboutMe: ""
        },
    ]
];

export const ProductsList = [
    {
      lineImage: connectionImage,
      lineName: "Connection",
      lineProducts: [
        {
              description: {
                  lineName: "Connection",
                  mainImage: connectionImage,
                  sampleImage: connectionSampleImage,
                  text: "CONNECTION é a linha de multifuncionais da Vitallis Profissional composta por: BB Cream, CC Bifásico e DD Cream. Produtos que possuem ações diversas e instantâneas de proteção e tratamento.",
              },
              products: [ 
                  {
                      mainImage: connectionSliderImage,
                      title: "BB Cream",
                      text: "O BB CREAM é um bálsamo de beleza multifuncional indicado para todos os tipos de cabelos, ideal para uso antes e depois de escovas e para hidratação noturna. Ele restaura a resistência, o brilho e a maciez de todos os tipos de cabelos, especialmente os danificados."
                  },
                  {
                      mainImage: connectionSliderImage2,
                      title: "CC Bifásico",
                      text: "Surpreendente fórmula bifásica desenvolvida para todos os tipos de cabelos, alia a reparação dos óleos ao poder supercondicionante dos seus ativos que penetram profundamente em cada fio poroso e sensibilizado. Com a reposição instantânea da proteína natural, os cabelos ficam saudáveis, com brilho e maleabilidade. Com componentes específicos e fórmula de ação rápida, penetram no ponto em que o cabelo precisa para ser instantaneamente restaurado e protegido."
                  },
                  {
                      mainImage: connectionSliderImage3,
                      title: "DD Cream",
                      text: "Indicado para todos os tipos de cabelos, promove reconstrução de cabelos ressecados, desvitalizados, quebradiços e quimicamente tratados, como também cabelos que fazem uso constante do secador e prancha. Composição enriquecida com aminoácidos e ativos hidratantes proporciona de forma inteligente o condicionamento e brilho tridimensional, efeito antifrizz e efetiva proteção da cor."
                  }
              ]
        }
      ]
    },
    {
        lineImage: recoveryImage,
        lineName: "Recovery",
        lineProducts: [
            {
                description: {    
                    lineName: "Recovery",
                    mainImage: recoveryDescriptionMainImage,
                    sampleImage: recoverySampleImage,
                    text: "Excepcional tratamento pós-química da Vitallis Profissional, que visa à reposição de carbono na fibra capilar, que é composta 45% de carbono, e proteção contra danos causados por processos químicos. Através da sinergia do exclusivo complex carbon, cistina e óleo de babaçu, o tratamento age de forma inteligente em todas as fases da cutícula, córtex, e cimento intercelular eliminando a porosidade, promovendo reidratação aumentando assim a flexibilidade, resistência e brilho dos cabelos devolvendo sua condição original.",
                },
                products: [ 
                    {
                        mainImage: recoverySliderImage4,
                        title: "Shampoo Repair Step 1",
                        text: "O Shampoo Repair Step 1 Recovery Reposição de Carbono Vitallis foi especialmente elaborado para cabelos ressecados ou danificados por processos químicos, contem em sua formulação ingredientes naturais que promovem uma limpeza suave e reparadora, restaurando os danos da fibra capilar. Melhora a textura e confere nutrição. Protege os cabelos dos danos causados pelo sol."
                    },
                    {
                        mainImage: recoverySliderImage3,
                        title: "Instant Power Step 2",
                        text: "As ampolas Instant Power Recovery Reposição de Carbono da Vitallis são indicadas para cabelos ressecados ou que passaram por processo químicos. Possui uma fórmula poderosa altamente concentrada que atua de forma inteligente e imediata atraves da sinergia do exclusivo Complex Carbon, cistina e óleo de babaçu, repondo carbono em todas as fases da cutícula e córtex, com efeitos bio positivos devolvendo brilho, maciez e elasticidade original dos cabelos. Confere proteção extra a futuros danos oxidativos."
                    },
                    {
                        mainImage: recoverySliderImage,
                        title: "Blend Fibre Step 3",
                        text: "O Blend Fibre Recovery Reposição de Carbono Vitallis contém moléculas protéicas especiais que aderem-se as areas danificadas dos cabelos promovendo selamento das cutículas e reposição da massa perdida durante processos químicos. Elimina a porosidade. Promove hidratação intensa. Aumenta a flexibilidade, resistencia e brilho dos cabelos. Protege os cabelos dos danos causados pelo sol."
                    },
                    {
                        mainImage: recoverySliderImage2,
                        title: "Deep Mask Step 4",
                        text: "O Deep Mask é um sistema único para reparação da fibra capilar indicado para cabelos resscados e que passaram por processos químicos. Desenvolvido a base de fibras de carbono que reveste os fios formando um filme protetor contra as agressões externas, conferindo força e resistência aos cabelos. Promove toque diferenciado, brilho, maciez e devolve de forma inteligente todo o carbono perdido pelo uso excessivo de produtos qumicos (alisamentos, progressivas, tinturas, etc.), recuperando assim a condição original dos cabelos. Protege os cabelos dos danos causados pelo sol."
                    },
                    {
                        mainImage: recoverySliderImage5,
                        title: "Shampoo Hidrating",
                        text: "O Shampoo Hidrating Recovery Reposição de Carbono Vitallis foi elaborado para cabelos ressecados ou danificados por processos químicos. Contém ingredientes suaves e naturais que promovem uma limpeza sem agredir os fios, deixando-os hidratados, alinhados e facéis de desembaraçar."
                    },
                    {
                        mainImage: recoverySliderImage6,
                        title: "Replenishing Moisturizer",
                        text: "O Replenishing Moisturizer Recovery Reposição de Carbono Vitallis é indicado para cabelos ressecados e que passaram por processos químicos. Desenvolvido a base de fibras de carbono que reveste os fios formando um filme protetor contra as agressões externas, conferindo força e resistência aos cabelos. Promove toque diferenciado, brilho, maciez e devolve de forma inteligente todo o carbono perdido pelo uso excessivo de produtos químicos (alisamentos, progressivas, tinturas, etc.). Recupera a condição original dos cabelos."
                    },
                    {
                        mainImage: recoverySliderImage7,
                        title: "Finisher Defrizzer",
                        text: "O Finisher Defrizzer Recovery Reposição de Carbono Vitallis é um defrizante de uso diario, facilita o processo de escovação e protege contra ação do calor. Indicado para cabelos ressecados ou que passaram por processos químicos. Contém o exclusivo Complex Carbon que atua de forma inteligente repondo carbono em todas as fases da cutícula e córtex restaurando os fios quebradiços. Desembaraça, promove brilho e maciez.Protege os cabelos dos danos causados pelo sol."
                    },
                ]
            }
        ]
    },
    {
        lineImage: lissImage,
        lineName: "Liss.Day",
        lineProducts: [
            {
                description: {
                    lineName: "Liss.Day",
                    mainImage: lissDescriptionMainImage,
                    sampleImage: lissSampleImage,
                    text: "LISS.DAY é uma escova progressiva inovadora, que age através da sinergia de complexos lipofílico de alto desempenho, com alto poder de penetração na fibra, que em conjunto com a molécula Pro.Liss 100 trata enquanto alisa os fios. Promove o reposicionamento das pontes dissulfeto do cabelo, deixando-os naturalmente lisos e brilhantes. Possui ainda em sua formulação o Active Color Protection, ativo rico em antioxidantes, que protege os fios do atrito provocado pela prancha minimizando o desbotamento da cor.",
                },
                products: [
                    {
                        mainImage: lissSliderImage3,
                        title: "Sérum Reductor",
                        text: "O Sérum Reductor Liss.Day Vitallis é uma escova progressiva inovadora que trata enquanto alisa os fios. Protege os fios do atrito provocado pela prancha Minimiza o desbotamento da cor. Não deixa o cabelo amarelado ou alaranjado. Tempo reduzido de aplicação. Resultado natural e duradouro. Fórmula leve, que pode dispensar o enxágue. Não contém formol ou derivados. Não causa dano à estrutura capilar"
                    },
                    {
                        mainImage: lissSliderImage4,
                        title: "Shampoo Conditioning",
                        text: "O Shampoo Conditioning Liss.Day Vitallis foi elaborado para cabelos que passaram por processo de progressiva. Sua formulação é livre de sulfatos, contem ingredientes suaves e naturais que promovem limpeza e condicionamento sem agredir os fios, deixando-os hidratados, alinhados e facéis de desembaraçar."
                    },
                    {
                        mainImage: lissSliderImage,
                        title: "Balm Sealing Intense",
                        text: "O Balm Sealing Intense Liss.Day Vitallis é indicado para cabelos que passaram por processo de progressiva. Contém em sua formulação o lumini system, sistema único para retexturização da fibra capilar e o active color protection, ativo rico em antioxidantes, composto por proteínas de arroz, linhaça, trigo e quinoa, associado aos benefícios do goji berry e blueberry, rico em fibras e aminoácidos, de alta substantividade com o cabelo que proporcionam de forma inteligente ação regeneradora, protetora, condicionamento e brilho tridimensional, como também efeito liso prolongado e ação anti frizz. Diminui o volume, hidrata e condiciona os fios, devolvendo brilho e maciez."
                    },
                    {
                        mainImage: lissSliderImage2,
                        title: "Leave-In Thermo Recontructor",
                        text: "O Leave-in Thermo Recontructor Liss.Day Vitalis é indicado para cabelos que passaram por processo de progressiva. Protege os cabelos secos e sensibilizados do calor do secador e da prancha. Sua ação desembaraça e repara os fios instantaneamente, recuperando sua vitalidade nutrindo profundamente os fios. Sua fórmula ainda garante a selagem das cutículas capilares. Desembaraça. Promove brilho e maciez."
                    }
                ]
            }
        ]
    },
    {
        lineImage: sublimeImage,
        lineName: "Sublime Curls",
        lineProducts: [
            {
                description: {
                    lineName: "Sublime Curls",
                    mainImage: sublimeDescriptionMainImage,
                    sampleImage: sublimeSampleImage,
                    text: "A linha Sublime Curls Vitallis é um sistema de alisamento e relaxamento indicado para o típico cabelo brasileiro, originário da vasta mistura de raças e que se apresentam crespos, cacheados e extremamente resistentes. A tecnologia deste sistema permite desde uma abertura de cachos até um perfeito alisamento. Desenvolvido com ativos totalmente naturais, que possuem propriedades hidratantes, emolientes e restauradoras, mantendo assim o equilíbrio natural dos fios durante toda a ação do produto. Possui ainda propriedades nutritivas e antioxidantes, que promovem a lubrificação das cutículas, reduzindo o atrito na superfície capilar, garantindo proteção durante o processo de alisamento ou relaxamento capilar, proporcionando aos cabelos cachos mais definido e com aspecto leve e natural ou um alisamento intenso e nutritivo.",
                },
                products: [
                    {
                        mainImage: sublimeSliderImage3,
                        title: "Creme Relaxante Hidróxido de Cálcio",
                        text: "Elaborado com hidróxido de cálcio, álcali ph 12,0, o Creme Relaxante Sublime Curls Vitallis só possui ação alisante/relaxante quando reage com o carbonato de guanidina. O hidróxido de cálcio quando ativado, atua sobre as pontes de cistina da queratina do cabelo, relaxando os cachos ou até mesmo alisando, basta para isso controlar o tempo e forma de processamento do fio, sendo assim, bastante versátil. Sua base emoliente é enriquecida com óleo de baobá, manteigas de cupuaçu e karité, que juntas hidratam protegem, reparam e nutre os cabelos durante o processo de alisamento e relaxamento."
                    },
                    {
                        mainImage: sublimeSliderImage5,
                        title: "Líquido Ativador Carbonato de Guanidina",
                        text: "Elaborado com carbonato de guanidina, sal orgânico não metálico tem o ph 11, possui ação relaxante quando reage com o hidróxido de cálcio, formando o hidróxido de guanidina. O hidróxido de guanidina formado atua sobre as pontes cistinas de queratina do cabelo relaxando os cachos ou até mesmo alisando, bastando para isso, controlar tempo e a forma de processamento do fio, sendo, portanto bastante versátil. Protege os cabelos dos danos causados pelo sol."
                    },
                    {
                        mainImage: sublimeSliderImage6,
                        title: "Shampoo Indicador",
                        text: "O Shampoo Indicador Sublime Curls Vitallis indica e remove todos os resíduos de hidróxido de guanidina e do protetor de couro. Desembaraça e condiciona os fios deixando-os macios e sedosos, além de reequilibrar o ph dos cabelos. A cor rosada irá indicar resíduos de creme relaxante que deverão ser removidos."
                    },
                    {
                        mainImage: sublimeSliderImage4,
                        title: "Gel Protetor de Couro Cabeludo",
                        text: "O Gel Protetor de Couro Cabeludo Sublime Curls Vitallis foi desenvolvido para proteger o couro cabeludo e os fios contra a ação agressiva de químicas alcalinas. Isola e protege durante todo processo de transformação capilar. Pode ser utilizado em quaisquer técnicas de transformação capilar (relaxamento, alisamento, onduladores)."
                    },
                    {
                        mainImage: sublimeSliderImage7,
                        title: "Soft Shampoo LOW-POO",
                        text: "O Soft Shampoo Manutenção Sublime Curls Vitallis é um shampoo de limpeza suave indicado para cabelos crespos e cacheados, é livre de sulfactantes agressivos , corantes, silicones e petrolatos. Possui em sua formulação ativos de origem vegetal que contribuem para que a higienização seja feita de forma suave sem agredir e ressecar a estrutura dos fios preservando a oleosidade natural dos cabelos. Proporcionando assim brilho, condicionamento e maciez."
                    },
                    {
                        mainImage: sublimeSliderImage8,
                        title: "Ultra Hidratante NO-POO | LOW-POO",
                        text: "O Ultra Hidratante Manutenção Sublime Curls Vitallis foi especialmente desenvolvido para tratar os cabelos crespos e cacheados. Possui em sua formulação alta concentração de óleos vegetais que hidratam e nutrem os cabelos crespos e cacheados de forma natural, conta ainda com o excepcional ativo, luna matrix, sistema único de gel lamelar que repõe a massa perdida dos fios, reparando-os internamente e externamente, eliminando a porosidade, e promovendo brilho e resistência aos fios."
                    },
                    {
                        mainImage: sublimeSliderImage2,
                        title: "Conditioner Co-wash NO-POO",
                        text: "O Conditioner Co-wash Manutenção Sublime Curls Vitallis é um condicionador que promove uma limpeza delicada dos fios e couro cabeludo ao mesmo tempo em que condiciona, respeitando a fisiologia dos cabelos crespos e cacheados. Sua formulação é totalmente livre de sulfatos, petrolatos e parabenos; promovendo assim uma limpeza suave e condicionamento sem agressão aos fios. Protege os cabelos dos danos causados pelo sol."
                    },
                    {
                        mainImage: sublimeSliderImage,
                        title: "Ativador de Cachos",
                        text: "O Ativador de Cachos Sublime Curls Vitallis foi desenvolvido para manter o cacheado dos cabelos por muito mais tempo, de forma natural e saudável. Restaura e protege os cabelos, deixando-os com maior elasticidade, brilhantes e fáceis de modelar. Controla o volume, condiciona e promove maciez aos cabelos, além de combater o frizz."
                    }
                ]
            }
        ]
    },
    {
        lineImage: desiredImage,
        lineName: "Desired Color",
        lineProducts: [
            {
                description: { 
                    lineName: "Desired Color",
                    mainImage: desiredDescriptionMainImage,
                    sampleImage: desiredSampleImage,
                    text: "A Linha Desired Color Vitallis Profissional foi desenvolvida com o mais alto padrão de qualidade e tecnologia. Uma linha completa e sofisticada exclusiva para uso profissional, rica em proteínas e ativos naturais da Amazônia que oferece cuidado, respeito ao fio e maior proteção do couro cabeludo, permitindo a criação de nuances perfeitas com precisão. Coloração Permanente",
                },
                products: [ 
                    {
                        mainImage: desiredSliderImage,
                        title: "Coloração Creme",
                        text: "A Coloração Creme Permanente Desired Color Vitallis foi desenvolvida com micropigmentos em cristais, uma das tecnologias mais avançadas para a fixação da cor perfeitas nos cabelos. São 58 cores misturáveis entre sí, que permite a criação de inúmeras nuances de alta qualidade para os profissionais mais exigentes. Sua cobertura é de 100% dos fios brancos com brilho e durabilidade. Os pigmentos da Coloração Desired Color Vitallis são resistentes à luz e às lavagens dos fios, o que garante proteção contra o desbotamento e intensidade até o próximo retoque da raiz."
                    },
                    {
                        mainImage: desiredSliderImage2,
                        title: "Tonalizante Creme",
                        text: "O Tonalizante Creme Desired Color Vitallis é ideal para mudanças de cor, neutralização de mechas sensibilizadas, após serviços de escovas progressivas ou relaxamento e para realizar manutenção da cor em cabelos coloridos. Sua tecnologia sem amônia e com ativos da amazônia permite tratar enquanto tonaliza, proporcionando resultados naturais e fios mais saudáveis, podendo ser usado no mesmo dia da realização de outros processos químicos. São 26 cores, totalmente livres de amônia e com garantia de cobertura total e máxima fixação em cabelos brancos."
                    },
                    {
                        mainImage: desiredSliderImage3,
                        title: "Oxidante",
                        text: "A agua oxigenada Desired Color Vitallis estabilizada é ideal para o uso no processo de descoloração e coloração garantindo um resultado perfeito e uniforme. Possuem 4 volumagens: 10 volumes (matizar, tom sobre tom, manter a cor e escurecer o cabelo) 20 volumes (1 a 2 tons em cabelos naturais) 30 volumes (2 a 3 tons em cabelos naturais) 40 volumes (3 a 4 tons em cabelos naturais)"
                    },
                    {
                        mainImage: desiredSliderImage4,
                        title: "Pó Descolorante",
                        text: "O pó descolorante Desired Color Vitallis é indicado para todas as técnicas de descoloração, mechas, reflexos e balaiagem. Contém em sua formulação silicones e óleos selecionados que minimizam os danos durante o processo de descoloração mantendo a integridade da estrutura dos cabelos. Dust free, não levanta pó ao manuseio. Disponível em 2 versões: o Azul tradicional e o Violeta com a inovadora ação antiamarelamento, possui em sua formulação pigmentação violeta para neutralizar tons quentes durante o clareamento."
                    }
                ]
            }
        ]
    },
    {
        lineImage: powerImage,
        lineName: "Power Relieve",
        lineProducts: [
            {
                description: {    
                    lineName: "Power Relieve",
                    mainImage: powerDescriptionMainImage,
                    sampleImage: powerSampleImage,
                    text: "As ampolas Power Relieve são extremamente versáteis e poderosas. Podem ser usadas antes, durante e após processos químicos, em cabelos ressecados, naturais e extremamente danificados. Nutre enquanto repara os danos mais profundos. São 03 tipos de ampolas que se adequam a necessidade dos cabelos, pois contam com a combinação de ativos selecionados, com alto poder de penetração na fibra capilar, como Semi di Lino, Cistina, Vitaminas A, B, Ômega 3, Ômega 6 e óleos nobres que juntos promovem uma reestruturação da fibra capilar de dentro para fora, devolvendo maciez e luminosidade. Além disso, sela as cutículas e alinha a fibra capilar, proporcionando vitalidade e toque aveludado.",
                },
                products: [
                    {
                        mainImage: powerSliderImage3,
                        title: "Power Relieve Semi di Lino",
                        text: "A ampola Power Relieve Semi di Lino Vitallis é indicada para cabelos danificados. Nutre enquanto repara os danos mais profundos. Conta com a exclusiva combinação do extrato de linhaça, rico em vitaminas A, B, ômega 3, ômega 6 e do Lumini System que juntos promovem uma reestruturação da fibra capilar de dentro para fora, devolvendo maciez e luminosidade. Além disso, sela as cutículas e alinha a fibra capilar, proporcionando vitalidade e toque aveludado."
                    },
                    {
                        mainImage: powerSliderImage2,
                        title: "Power Relieve Mousse",
                        text: "A ampola Power Relieve Mousse Hidratação Instantânea Vitallis é indicada para todos os tipos de cabelos. Promove uma ultra hidratação nos cabelos, condicionando e nutrindo profundamente. Sua formulação contem óleo de babaçu, emoliente natural que cria uma pelicula de proteção para bloquear a umidade, evitando o frizz e disciplinando os fios, vitaminas e omegas 3 e 6, excelentes para devolver a hidratação dos fios. Condiciona, desembaraça e reequilibra o pH, deixando os cabelos super macios e extremamente agradáveis ao toque. "
                    },
                    {
                        mainImage: powerSliderImage,
                        title: "Power Relieve Cistina",
                        text: "A ampola Power Relieve Cistina foi elaborada para cabelos enfraquecidos e extremamente danificados. A base de complexo de aminoácidos, proteínas hidrolizadas e vitaminas, age instantaneamente penetrando na fibra capilar reconstruindo as estruturas internas e externas dos fios, conferindo força, proteção e reparação. Promove ainda a selagem das cutículas, proteção da cor e recuperação da elasticidade natural dos fios. O resultado são fios mais fortes, revitalizados e disciplinados, com movimento e brilho natural."
                    }
                ]
            }
        ]
    },
    {
        lineImage: adoroImage,
        lineName: "Ad'oro",
        lineProducts: [
            {
                description: {
                    lineName: "Ad'oro",
                    mainImage: adoroDescriptionMainImage,
                    sampleImage: adoroSampleImage,
                    text: "A linha Ad’oro Vitallis é um tratamento intensivo com ação multi reparadora indicada para cabelos sensibilizados pelos processos químicos, mecânicos, térmicos e/ou agentes externos. A linha é formulada com ácido lático, semi dilino e o inovador sistema, reparage, composto por proteínas e uma sequência dos principais aminoácidos estruturais da queratina: serina, ácido glutâmico e cisteína que juntos agem tanto no córtex quanto na cutícula repondo massa e reparando danos, proporcionando assim resistência, brilho e maciez dos fios. Forma um filme protetor reduzindo a porosidade promovendo assim uma proteção superior contra danos futuros.",
                },
                products: [ 
                    {
                        mainImage: adoroSliderImage,
                        title: "Gold Mask",
                        text: "A Gold Mask Ad’oro Vitallis é um tratamento intensivo com ação multi reparadora indicada para cabelos sensibilizados pelos processos químicos, mecânicos, térmicos e/ou agentes externos. Formulado com ácido lático, semi dilino e o inovador sistema Reparage, composto por proteínas e uma sequência dos principais aminoácidos estruturais da queratina: serina, ácido glutâmico e cisteína. Agem tanto no córtex quanto na cutícula repondo massa e reparando danos, proporcionando assim resistência, brilho e maciez dos fios. Forma um filme protetor reduzindo a porosidade promovendo assim proteção superior contra danos futuros."
                    },
                    {
                        mainImage: adoroSliderImage2,
                        title: "Shampoo Gold Hydration",
                        text: "O Shampoo Gold Hydration Ad’oro Vitallis é um shampoo de limpeza suave indicado para cabelos ultra sensibilizados pelos processos químicos, mecânicos, térmicos e/ou agentes externos. Composto por proteínas e uma sequência dos principais aminoácidos estruturais da queratina: serina, ácido glutâmico e cisteína que garantem reparação superior contribuindo para que a higienização seja feita de forma suave sem agredir e ressecar a estrutura dos fios preservando a oleosidade natural dos cabelos. Proporcionando assim brilho, condicionamento e maciez."
                    }
                ]
            }
        ]
    },
    {
        lineImage: superImage,
        lineName: "Super Sleek",
        lineProducts: [
            {
                description: {
                    mainImage: superDescriptionMainImage,
                    sampleImage: superSampleImage,
                    text: "A linha Super Sleek é um Sistema de alisamento e relaxamento capilar a base tioglicolato de amônio, ativo químico que age nas cadeias internas dos fios responsáveis por sua forma. Contém em sua formulação a riqueza do óleo de argan, macadâmia e extrato de algas marinhas que juntos proporcionam uma proteção diferenciada que resulta em fios extremamente lisos e alinhado com aspecto natural.",
                },
                products: [ 
                    // {
                    //     mainImage: "",
                    //     title: "Loção Neutralizante",
                    //     text: "Neutralizante para tioglicolato de amônia. Seu uso é indispensável para a fixação da forma lisa e neutralização da ação do creme de relaxamento, com função de restabelecer as ligações internas dos fios responsáveis por seu formato. Os ativos presentes na formulação atuam intensamente em toda estrutura capilar proporcionando proteção e tratamento aos cabelos. Nutre, hidrata e revitaliza, dando a nova forma dos fios muito mais brilho e sedosidade."
                    // },
                    {
                        mainImage: superSliderImage,
                        title: "Sos Cistina",
                        text: "SoS Cistina Super Sleek Vitallis Profissional é um concentrado de cistina e amino nutrientes elaborada para cabelos que passaram por processos químicos, enfraquecidos e extremamente danificados. A base de complexo de aminoácidos, proteínas hidrolizadas e vitaminas, age instantaneamente penetrando na fibra capilar reconstruindo as estruturas internas e externas dos fios, conferindo força, proteção e reparação. Promove ainda a selagem das cutículas, proteção da cor e recuperação da elasticidade natural dos fios."
                    },
                    // {
                    //     mainImage: "",
                    //     title: "Creme Relaxante Capilar",
                    //     text: "Alisamento e relaxamento capilar a base tioglicolato de amônio."
                    // },
                ]
            }
        ]
    },
    {
        lineImage: limitedImage,
        lineName: "Limited Edition",
        lineProducts: [
            {
                description: {
                    lineName: "Limited Edition",
                    mainImage: limitedDescriptionMainImage,
                    sampleImage: limitedSampleImage,
                    text: "A linha Limited Edition foi desenvolvida para atender a necessidade do mercado, que busca os tratamentos que estão na moda, mas que precisavam de uma qualidade superior.",
                },
                products: [
                    {
                        mainImage: limitedSliderImage,
                        title: "Anabolizante capilar Máscara Regeneradora",
                        text: "A Máscara Regeneradora Anabolizante Capilar Limited Edition Vitallis é um suplemento capilar indicado para cabelos agredidos e fragilizados pelo constante uso de químicas. Contem em sua formulação ativos de baixo peso molecular que possuem facilidade de penetração na cutícula dos cabelos; atuam fortalecendo os fios prevenindo a quebra, e auxiliando no crescimento capilar. Repõe ainda aminoacidos e proteínas blindando as cuticulas promovendo assim brilho, reparação, hidratação e maciez aos fios.Resultado imediato."
                    },
                    {
                        mainImage: limitedSliderImage4,
                        title: "Desmaia Cabelos K.O.",
                        text: "A máscara Knockout Ultra Nutritiva Desmaia Cabelo Limited Edition Vitallis é indicada para todos os tipos de cabelos. Hidrata e nutre profundamente os fios de dentro para fora. Sua formulação é enriquecida com exclusivo complexo de óleos nutritivos (óleo de cálamo, mirra, canela da china e oliva) que juntos proporcionam nutrição e restauração profunda, sem pesar os cabelos. Selam os fios, conferindo maciez, brilho e combate ao frizz."
                    },
                    {
                        mainImage: limitedSliderImage3,
                        title: "Botox Capilar Selagem Reparadora",
                        text: "A Selagem Reparadora Botox Capilar Vitallis conta com uma tecnologia inovadora a base de amidas acidas derivadas dos aminoacidos da queratina que permite o realinhamento dos fios. Seus ativos transportam proteínas as fissuras das cutículas, reparando e combatendo a porosidade. Promove um selamento superior proporcionando assim maciez, sedosidade e um liso natural aos cabelos."
                    },
                    {
                        mainImage: limitedSliderImage2,
                        title: "Banho de Colágenos Máscara ultra Hidratante",
                        text: "A Máscara Ultra Hidratante Banho de Colágenos Limited Edition Vitallis é um tratamento extremamente profundo e imediato com alta capacidade de hidratar e umectar os fios. É indicado para cabelos quimicamente tratados. Sua formulação foi especialmente desen-volvida com colágenos vegetais e ácido lático que juntos atuam promovendo máxima hidratação, nutrição e reconstrução intensiva aos cabelos, alem de proteger contra danos futuros. O colágeno atua retendo a água no fio, preenchendo as ranhuras e uniformizando a cutícula que torna o produto tambem muito eficaz para tratar cabelos cacheados e crespos."
                    }
                ]
            }
        ]
    }, 
    {
        lineImage: viplexImage,
        lineName: "V.I.Plex",
        lineProducts: [
            {
                description: {
                    lineName: "V.I.Plex",
                    mainImage: viplexDescriptionMainImage,
                    sampleImage: viplexSampleImage,
                    text: "A linha V.I.Plex Blindagem e Reparação Capilar Vitallis foi especialmente desenvolvida para protege e tratar a estrutura dos cabelos, antes, durante e após procedimentos químicos. Regenera a fibra capilar e intensifica o clareamento nos processos de descoloração e coloração. Contém em sua formulação uma matriz amino-estrutural composta por uma sequência de aminoácidos, gluco-nutrientes e proteínas em um sistema de polímeros de diferentes pesos moleculares que atuam promovendo uma blindagem amino-estrutural ao mesmo tempo em que repõe micros nutrientes. Permite de forma rápida e segura atingir tons de loiros claros e loiros platinados sem causar dano aos fios. Confere ainda selagem das cutículas e proteção da cor.",
                },
                products: [ 
                    {
                        mainImage: vipSliderImage2,
                        title: "Power Dose Step 1",
                        text: "As ampolas VIPlex foram especialmente desenvolvidas para proteger e tratar a estrutura dos cabelos, antes, durante e após procedimentos químicos. Regenera a fibra capilar e intensifica o claremaneto nos processos de descoloração e coloração. Permite de forma rápida e segura atingir tons de loiros claros e loiros platinados sem causar dano aos fios."
                    },
                    {
                        mainImage: vipSliderImage,
                        title: "Máscara Repositora Step 2",
                        text: "A Máscara Repositora V.I.Plex Vitallis é indicada para cabelos que passaram por processo de descoloração e coloração. Além de restaurar e hidratar fortalece o cabelo, combate as pontas duplas, intensifica a cor e proporciona aspecto e movimento natural."
                    },
                ]
            }
        ]
    },
    {
        lineImage: intenseImage,
        lineName: "Intense Therapy",
        lineProducts: [
            {
                description: {
                    lineName: "Intense Therapy",
                    mainImage: intenseImage,
                    sampleImage: null,
                    text: "Para a manutenção diária da saúde dos seus cabelos, desenvolvemos a linha Premium Intense Therapy, que em três versões diferentes, age segundo a necessidade de cada tipo de cabelo"
                    // text: "Manutenção cabelos secos O Kit Intense Therapy Cabelos Secos e Hidratados foi especialmente desenvolvido para cabelos ressecados e danificados por processo de escova, chapinha, piscina e sol. Sua formulação contém óleos vegetais nobres associados a manteigas, polímeros e silicones e um exclusivo complexo de minerais, que juntos restauram a hidratação e a flexibilidade natural dos fios, proporcionando excelente condicionamento e hidratação. Manutenção cabelos normais e oleosos O Kit Intense Therapy Cabelos Normais e Oleosos foi especialmente desenvolvido para proporcionar uma higienização dos cabelos e couro cabeludo estimulando assim a circulação periférica diminuindo a produção de sebo. Contém em sua formação ativos especiais, como óleo de menta, piperita e erva doce, que agem eliminando os fungos e bactérias promovendo sensação refrescante deixando os cabelos macios e sedosos. Manutenção Cabelos Quimicamente Tratados O Kit Manutenção Intense Therapy Cabelos Quimicamente Tratados foi desenvolvido com uma formulação exclusiva, enriquecida por óleos vegetais, manteiga de karité, polímeros, silicones e colágenos que promovem a reposição da massa proteica dos fios danificados por processos químicos e físicos, proporcionando excelente condicionamento e hidratação, deixando-os bonitos, macios e sedosos, além de devolver a elasticidade natural. ",
                },
                products: [
                    {
                        mainImage: intenseSliderImage15,
                        title: "Shampoo - Para Cabelos Quimicamente Tratados",
                        text: "Contém silicones e colágenos que promovem a reposição de massa proteica dos fios danificados em processo químico"
                    },
                    {
                        mainImage: intenseSliderImage7,
                        title: "Hidratante - Para Cabelos Quimicamente Tratados",
                        text: "Contém silicones e colágenos que promovem a reposição de massa proteica dos fios danificados em processo químico"
                    },
                    {
                        mainImage: intenseSliderImage3,
                        title: "Condicionador - Para Cabelos Quimicamente Tratados",
                        text: "Contém silicones e colágenos que promovem a reposição de massa proteica dos fios danificados em processo químico"
                    },
                    {
                        mainImage: intenseSliderImage5,
                        title: "Creme Para Pentear - Para Cabelos Quimicamente Tratados",
                        text: "Contém silicones e colágenos que promovem a reposição de massa proteica dos fios danificados em processo químico"
                    },
                    {
                        mainImage: intenseSliderImage9,
                        title: "Shampoo - Para Cabelos Secos e Desidratados",
                        text: "Com exclusivo complexo de minerais associados a manteiga e polímeros, que juntos restauram a hidratação e a flexibilidade natural dos fios;"
                    },
                    {
                        mainImage: intenseSliderImage6,
                        title: "Hidratante - Para Cabelos Secos e Desidratados",
                        text: "Com exclusivo complexo de minerais associados a manteiga e polímeros, que juntos restauram a hidratação e a flexibilidade natural dos fios;"
                    },
                    {
                        mainImage: intenseSliderImage,
                        title: "Condicionador - Para Cabelos Secos e Desidratados",
                        text: "Com exclusivo complexo de minerais associados a manteiga e polímeros, que juntos restauram a hidratação e a flexibilidade natural dos fios;"
                    },
                    {
                        mainImage: intenseSliderImage4,
                        title: "Creme Para Pentear - Para Cabelos Secos e Desidratados ",
                        text: "Com exclusivo complexo de minerais associados a manteiga e polímeros, que juntos restauram a hidratação e a flexibilidade natural dos fios;"
                    },
                    // {
                    //     mainImage: intenseSliderImage10,
                    //     title: "Shampoo Hidratante Step-1",
                    //     text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    // },
                    // {
                    //     mainImage: intenseSliderImage12,
                    //     title: "Shampoo Hidratante Step-3",
                    //     text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    // },
                    // {
                    //     mainImage: intenseSliderImage13,
                    //     title: "Shampoo Hidratante Step-4",
                    //     text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    // },
                    // {
                    //     mainImage: intenseSliderImage11,
                    //     title: "Condicionador Retexturizante Step-2",
                    //     text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    // },
                    {
                        mainImage: intenseSliderImage14,
                        title: "Shampoo - Para Cabelos Normais e Oleosos",
                        text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    },
                    {
                        mainImage: intenseSliderImage8,
                        title: "Hidratante - Para Cabelos Normais e Oleosos",
                        text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    },
                    {
                        mainImage: intenseSliderImage2, 
                        title: "Condicionador - Para Cabelos Normais e Oleosos",
                        text: "Contém ativos especiais como óleo de menta piperita e erva doce, que agem eliminando fungos e bactérias, promovendo sensação refrescante."
                    }
                ]
            }
        ]
    },
    {
        lineImage: blondImage,
        lineName: "Blond Desired",
        lineProducts: [
            {
                description: {
                    lineName: "Blond Desired",
                    mainImage: blondImage,
                    sampleImage: null,
                    text: "Especialmente desenvolvido para neutralizar gradativamente as tonalidades amareladas dos cabelos claros, mechados ou grisalhos causados por oxidação natural ou fatores externos, como radiação, poluição, água do mar ou piscina.",
                },
                products: [ 
                    {
                        mainImage: blondSliderImage4,
                        title: "Violet Shampoo - Manutenção",
                        text: "Especialmente desenvolvido para neutralizar gradativamente as tonalidades amareladas nos cabelos claros, mechados ou grisalhos causados pela oxidação natural ou fatores externos como radiação poluição, agua do mar ou piscina."
                    },
                    {
                        mainImage: blondSliderImage2,
                        title: "Violet Restructuring - Manutenção",
                        text: "Especialmente desenvolvido para neutralizar os tons amarelados nos cabelos loiros, mechados ou grisalhos. Conferindo reestruturação e hidratação intensiva intracortex, corrige as imperfeiçoes externas, proporcionando melhor penteabilidade. Devolve a maciez e vitalidade dos fios revelando loiro puro e radiante."
                    },
                    // {
                    //     mainImage: blondSliderImage3,
                    //     title: "Violet Shampoo",
                    //     text: "A Linha Blond Desired Profissional foi desenvolvida para o cuidado especifico dos cabelos loiros e grisalhos. Elaborada com micropigmentos de ültima geraqäo violeta e grafite para neutralizaqäo gradativa e diåria de tons amarelados em cabelos loiros naturais, coloridos, descoloridos ou grisalhos. Enriquecido com Öleo Argan e Nano Particulas de Sericina, que auxiliam na correqäo da porosidade devolvendo a massa proteica formando urn micro filme protetor que retém a hidrataqäo, devolvendo a vitalidade, maciez e brilho aos cabelos."
                    // },
                    // {
                    //     mainImage: blondSliderImage,
                    //     title: "Violet Hidratante",
                    //     text: "A Linha Blond Desired Profissional foi desenvolvida para o cuidado especifico dos cabelos loiros e grisalhos. Elaborada com micropigmentos de ültima geraqäo violeta e grafite para neutralizaqäo gradativa e diåria de tons amarelados em cabelos loiros naturais, coloridos, descoloridos ou grisalhos. Enriquecido com Öleo Argan e Nano Particulas de Sericina, que auxiliam na correqäo da porosidade devolvendo a massa proteica formando urn micro filme protetor que retém a hidrataqäo, devolvendo a vitalidade, maciez e brilho aos cabelos."
                    // },
                ]
            }
        ]
    },
    {
        lineImage: liveImage,
        lineName: "Live Liss",
        lineProducts: [
            {
                description: {
                    lineName: "Live Liss",
                    mainImage: liveImage,
                    sampleImage: null,
                    text: "O uso excessivo de progressivas causa danos aos fios como perda de massa proteica e lipídeos. Como resultado, perdem brilho e maleabilidade, ficam ressecados e com tendência à quebra. Para isso, a Vitallis Profissional desenvolveu a linha Manutenção Live Liss, com uma combinação exclusiva de silicones, nanoparticulas de sericina, aminoácidos e um  e um sistema de fibras de elasfômeros, que juntos devolvem a massa proteica perdida durante o processo de transformação, formando um micro filme protetor que retém a hidratação minimizando os danos causados por processos químicos, devolvendo a vitalidade, maciez e brilho aos cabelos. Prolonga o efeito liso.",
                },
                products: [ 
                    {
                        mainImage: liveSliderImage3,
                        title: "Shampoo Hidratante - Manutenção",
                        text: "O Shampoo Hidratante Live Liss, além de limpar os cabelos, hidrata e nutre os fios através de uma exclusiva combinação de polímeros e complexos de sais minerais de origem marinha que renova a estrutura dos cabelos conferindo brilho, maciez e penteabilidade."
                    },
                    {
                        mainImage: liveSliderImage2,
                        title: "Mask Repair",
                        text: "A Mask Repair Live Liss promove uma nano restauração e reconstrução da massa protéica dos fios danificados por processos químicos e físicos. Atua como um cimento na fibra capilar promovendo um perfeito selamento das cutículas, além de promover uma melhora visível na intensidade da cor."
                    },
                    {
                        mainImage: liveSliderImage,
                        title: "Gloss Finished - Manutenção",
                        text: "O Gloss Finished Live Liss é um finalizador sem enxague que proporciona aos dios o máximo de proteção. Reduz o frizz deixando os cabelos mais macios, sedosos e fáceis de pentear. Devolve o brilho e a luminosidade."
                    },
                ]
            }
        ]
    },
    {
        lineImage: essentialImage,
        lineName: "Essential Finished",
        lineProducts: [
            {
                description: {
                    lineName: "Essential Finished",
                    mainImage: essentialImage,
                    sampleImage: null,
                    text: "Uma linha especialmente elaborada para finalizar os melhores tratamentos, composta por: Finalizador de brilhos, reparador de pontas, argan oil fluid e disciplinador de fios.",
                },
                products: [ 
                    {
                        mainImage: essentialSliderImage5,
                        title: "Hair Spray",
                        text: `HairSpray+ Vitallis Profissional confere uma fixação extraforte e duradoura para todos os tipos de cabelo. HairSpray+ Vitallis Profissional garante um penteado perfeito com efeito de longa duração e resistente à umidade. O HairSpray+ Vitallis Profissional proporciona proteção contra a umidade, formando uma película envolta da fibra capilar sem pesar nos fios. Cabelos luminosos e macios, além de uma fragrância agradável.`
                    },
                    {
                        mainImage: essentialSliderImage3,
                        title: "Finalizador De Brilho",
                        text: "O finalizador de brilho da Vitallis Profissional é ideal para todas as ocasiões. Formulado com uma associação de silicones nobres que fornecem brilho tridimensional e suavidade aos cabelos rebeldes. Além de garantir proteção contra os odores, protege e elimina o cheiro de tabaco dos cabelos. Pode ser utilizado antes e depois da escova, é um produto que pode ser carregado na bolsa para possíveis situações em que seja necessário eliminar o odor de cigarro dos cabelos sem deixa-los pesados e oleosos, além de melhorar o brilho. "
                    },
                    {
                        mainImage: essentialSliderImage4,
                        title: "Reparador De Pontas",
                        text: "O reparador de pontas da Vitallis Profissional, foi desenvolvido especialmente para prevenir e reparar as pontas duplas, sela as cutículas hidrata e protege os fios das agressões, atua intensamente eliminado o frizz que insiste e permanecer mesmo após a escova. Com um aroma agradável que deixa os cabelos perfumados."
                    },
                    {
                        mainImage: essentialSliderImage,
                        title: "Oleo de Argam",
                        text: "Oleo de argan é rico em ácidos graxos insaturados, Ômega 9 e tocoferóis naturais e Vitamina E, consegue penetrar na fibra capilar instantaneamente, melhorando a elasticidade folicular e aumentando a nutrição além de proporcionar força e a saúde dos cabelos. Possui propriedade nutritiva e antioxidante devido a combinação de ácidos lipídicos e vitaminas, que promovem a lubrificação das cutículas, reduzindo o atrito na superfície capilar. Garante proteção durante o processo de alisamento capilar."
                    },
                    {
                        mainImage: essentialSliderImage2,
                        title: "Disciplinador De Fios",
                        text: "O disciplinador de fios da Vitallis Profissional é ideal para pré e pós-escova. A base de silicones nobres exclusivos que selam as cutículas e suavizam as pontas dos cabelos, eliminam o frizz, aumentando o brilho e a durabilidade da escova. Devido a sua versatilidade e por ser termo ativado pode ser utilizado em inúmeros procedimentos térmicos, garantido assim um cabelo mais protegido."
                    },
                ]
            }
        ]
    },
    {
        customClass: "product-wrapper adjust-plus-icon",
        lineImage: plasticaImage,
        lineName: "Plástica Premium",
        lineProducts: [
            {
                description: {
                    lineName: "Plástica Premium",
                    mainImage: plasticaMainImage,
                    sampleImage: null,
                    text: "A Plástica Premium Vitallis Profissional é um tratamento de alta performance, com efeito profundo e intenso, especialmente desenvolvido para cabelos sensibilizados, agredidos por processos químicos, opacos, quebradiços, com pontas duplas ou desgastados por agentes naturais do sol, sal e cloro. Garante reposição instantânea de queratina conferindo elasticidade e resistência à fibra capilar pois possui uma formulação enriquecida com uma sinergia de aminoácidos, proteínas e óleos vegetais que agem na reconstrução da fibra capilar repondo o seu manto hidrolipídico, conferindo aspecto sedoso e tratado aos cabelos, repara as camadas desgastadas e elimina os sinais da idade dos fios.",
                },
                products: [ 
                    {
                        mainImage: plasticSliderImage,
                        title: "Shampoo Restaurador Step 1",
                        text: "O Shampoo Restaurador Step 1 Plástica Premium Vitallis Profissional age repondo a queratina e promovendo limpeza dos fios. Possui ação revitalizante hidratante, regenera, protege e prepara toda a estrutura dos cabelos para absorver os nutrientes presentes nas próximas fases."
                    },
                    {
                        mainImage: plasticSliderImage2,
                        title: "Queratina Step 2",
                        text: "A Queratina Step 2 Plástica Premium Vitallis Profissional é um repositor instantâneo de queratina que contém micropartículas de princípios ativos enriquecidos e biocompatíveis com os fios que agem no interior da fibra capilar repondo os aminoácidos, os ativos hidratantes e os umectantes para reconstrução capilar. Indicado para cabelos agredidos por processos químicos que apresentam aspecto opaco, fraco, quebradiço e pontas duplas. Seus benefícios promovem a penetração dos ativos na cutícula e córtex reconstruindo toda fibra capilar selando fio a fio."
                    },
                    {
                        mainImage: plasticSliderImage3,
                        title: "Reconstrutor Active Step 3",
                        text: "O Reconstrutor Active Step 3 Plastica Premium Vitallis Profissional possui uma fórmula inédita que combina ativos de ação aceleradora que otimiza a reconstrução e alinha as cutículas dos fios. Com nutrientes, vitaminas, aminoácidos, manteigas e óleos que proporcionam aos cabelos a fixação de água nos fios, mantendo-os hidratados. Promove alta emoliência, selamento das cutículas e completa o tratamento de reconstrução."
                    },
                    {
                        mainImage: plasticSliderImage4,
                        title: "Leave-in Step 4",
                        text: "O Leave-in Step 4 Plástica Premium Vitallis Profissional possui proteção térmica, protege as propriedades naturais do cabelo, preservando o tratamento e a elasticidade, maciez e brilho."
                    },
                    {
                        mainImage: plasticSliderImage5,
                        title: "Shampoo Restaurador",
                        text: "O Shampoo Restaurador Plástica Premium Vitallis Profissional limpa suavemente o cabelo e o couro cabeludo, promovendo ação revitalizante, hidratante, regenera, protege e prepara os fios para a reconstrução capilar depositando poderosos nutrientes fio a fio. Indicado para cabelos agredidos, com aspecto danificado, poroso, opaco, frágeis e com pontas duplas."
                    },
                    {
                        mainImage: plasticSliderImage6,
                        title: "Máscara Restauradora",
                        text: "A Máscara Restauradora Plástica Premium Vitallis Profissional, promove a manutenção do tratamento dos fios. Hidrata, repara os fios danificados, alinha as cutículas, devolve a maciez e elimina a porosidade. Os cabelos ficam resistentes e com elasticidade natural."
                    },
                    {
                        mainImage: plasticSliderImage7,
                        title: "Oil Repair",
                        text: "O Oil Repair Plástica Premium Vitallis Profissional, proporciona de forma inteligente, condicionamento, brilho, efeito anti-frizz e efetiva proteção da cor com eficiente proteção térmica, restaura as propriedades naturais dos cabelos e atua ainda como defrizante preservando o tratamento."
                    },
                ]
            }
        ]
    }
];