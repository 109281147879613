import React from "react";
import './Header.css';

// Libraries
import { Link } from "react-router-dom";

// Images
// import lupa from '../../assets/images/lupa.svg';
import logo from '../../assets/images/logo.png';
import instagram from '../../assets/images/instagram-brands.svg';
import facebook from '../../assets/images/facebook-brands.svg';
import youtube from '../../assets/images/youtube-brands.svg';

// Components
import ButtonDistributor from '../../components/ButtonDistributor/ButtonDistributor';
import ButtonCourse from '../../components/ButtonCourse/ButtonCourse';

const Header = () => {

    return (
            <section className="products-header">
                <div className="top-header">
                    <div className="top-header-wrapper">
                        <div className="top-header-link-wrapper">
                            <Link to="/support" className="link">Suporte ao cabelereiro</Link>
                        </div>
                        <div className="top-header-social-wrapper">
                            <a className="top-header-social" href="https://www.instagram.com/vitallisprofissionaloficial/" target="_blank" rel="noreferrer">
                                <img alt="instagram-icon" src={instagram} />
                            </a>
                            {/* <a className="top-header-social" href="">
                                <img src={linkedin} />
                            </a> */}
                            <a className="top-header-social" href="https://www.youtube.com/channel/UCTJjjQu8_kHhj1V0OJgI5WQ" target="_blank" rel="noreferrer">
                                <img alt="youtube-icon" src={youtube} />
                            </a>
                            <a className="top-header-social" href="https://www.facebook.com/vitallisprofissional" target="_blank" rel="noreferrer">
                                <img alt="facebook-icon" src={facebook} />
                            </a>
                        </div>
                    </div>
                    <div className="thin-line"></div>
                </div>
                <div className="main-header">
                    <div className="main-header-wrapper">
                        <Link to="/" className="main-header-logo">
                            <img alt="vitallis-logo" src={logo} width="149px" />
                        </Link>
                        <div className="main-header-buttons">
                            <div className='flex main-header-buttons-wrapper'>
                                <div className="flex" style={{ width:'100%', justifyContent: "space-between" }}>
                                    <ButtonCourse text={"Atendimento ao Cabelereiro"} to={'/support'} type={'empty'}/>
                                    {/* <ButtonCourse text={"Cursos e Eventos"} to={'/courses'} type={'empty'}/> */}
                                </div>
                                <ButtonDistributor bgColor={'dark'}/>
                            </div>
                            {/* <div className="button-find-new-selling">
                                <img src={lupa} />
                                <input type="text" placeholder="Encontre um revendedor" />
                            </div> */}
                        </div>
                    </div>
                    <div className="fat-line"></div>
                </div>
            </section>

        
    );
}

export default Header;