import React from "react";
import './ButtonDistributor.css';

// Libraries
import { Link } from "react-router-dom";

const ButtonDistributor = ({bgColor, footer}) => {
    return (
        <>
            <a href={"/be-distributor"} className={bgColor+" buttonDistributor"} style={{display: footer ? 'flex' : 'none'}}>SEJA UM DISTRIBUIDOR</a>
            <Link to={"/be-distributor"} className={bgColor+" buttonDistributor"} style={{display: footer ? 'none' : 'flex'}}>SEJA UM DISTRIBUIDOR</Link>
        </>
    );
}

export default ButtonDistributor;